import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CustomMaterialModule } from './core/material.module';

import {MatToolbarModule} from '@angular/material/toolbar';

import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ComponentsModule } from './components/components.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { IonicStorageModule, Storage } from '@ionic/storage';
import { CacheModule } from "ionic-cache";
import { Http, HttpModule, RequestOptions } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { LoginActivate } from './services/login-activate/login-activate';
import { AuthService } from './services/auth/auth.service';
import { RouterModule } from '@angular/router';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { MatCheckboxModule } from '@angular/material/checkbox';


export function jwtOptionsFactory(storage) {
  return {
    tokenGetter: () => {
      return storage.get('id_token_clic_and_flash');
    },
    whitelistedDomains: ['www.pac-expertise-et-conseil.com']
  }
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicStorageModule.forRoot({
      name: 'clicandflash_db',
    }),
    IonicModule.forRoot(), 
    CacheModule.forRoot(),
    AppRoutingModule,
    CustomMaterialModule,
    MatToolbarModule,
    ComponentsModule,
    HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    RouterModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [Storage]
      }
    }),
    MatCheckboxModule
  ],
  providers: [
    AndroidPermissions,
    BarcodeScanner,
    StatusBar,
    SplashScreen,
    LoginActivate,
    AuthService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
