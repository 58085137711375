import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../auth/auth.service";
import { NavController } from '@ionic/angular';

@Injectable()
export class LoginActivate implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private navCtrl : NavController) {}
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
      return this.authService.loggedIn().then((isLoggedIn) => {
        if(!isLoggedIn) {
            this.navCtrl.navigateRoot(['/connexion']);
            return false;
        } else {
            return true;
        }
      })
  }
}