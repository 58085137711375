import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Storage } from '@ionic/storage';

import { Observable } from 'rxjs/Observable';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CacheService } from 'ionic-cache';



export interface User {
  memoid: string,
  nom: string,
  prenom: string,
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private cache: CacheService, public http: Http, private httpCli: HttpClient, private storage: Storage, public jwtHelper: JwtHelperService) {
  }

  login(user) {
    return new Promise((resolve, reject) => {
      this.storage.clear().then(data => {
        this.http.post(environment.urlApi + "connexion", { memoid: user.memoid, password: user.password })
          .subscribe(data => {
            if (data && data['_body'].indexOf('error') == -1) {
              var dataFromBody = JSON.parse(data['_body']);
              if (dataFromBody.ERREUR != undefined) {
                resolve(dataFromBody.ERREUR);
              } else {
                this.setCurrentUser(dataFromBody.id_token).then((res) => {
                  resolve(true);
                });
              }
            } else {
              resolve(JSON.parse(data['_body']));
            }
          },
            err => {
              reject(err);
            })
      });
    });
  }

  private setCurrentUser(token) {
    var authService = this;
    return new Promise((resolve, reject) => {
      this.storage.set('id_token_clic_and_flash', token).then(() => {
        authService.getCurrentUser();
        resolve(true);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  async getCurrentUser(forceRefresh = false): Promise<Observable<User>> {
    if (forceRefresh == true)
      this.cache.removeItem("info");
    let token = await this.storage.get('id_token_clic_and_flash');
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
    let request = this.httpCli.get<User>(environment.urlApi + 'utlisateur', {headers});
    return this.cache.loadFromDelayedObservable<User>('info', request, 'user', 60 * 60, 'all');
  }

  loggedIn() {
    return this.storage.get('id_token_clic_and_flash').then(token => {
      return !this.jwtHelper.isTokenExpired(token);
    });
  }

  public logout() {
    this.storage.clear();
    return Observable.create(observer => {
      this.deleteToken();
      observer.next(true);
      observer.complete();
    });
  }

  public deleteToken() {
    this.storage.remove('user_clic_and_flash');
    return this.storage.remove('id_token_clic_and_flash');
  }
}
