import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginActivate } from './services/login-activate/login-activate';

const routes: Routes = [
  { path: '', redirectTo: 'accueil', pathMatch: 'full', canActivate: [LoginActivate] },
  { path: 'accueil', loadChildren: './pages/accueil/accueil.module#AccueilPageModule', canActivate: [LoginActivate] },
  { path: 'connexion', loadChildren: './pages/connexion/connexion.module#ConnexionPageModule'},
  { path: 'liste/:id', loadChildren: './pages/liste/liste.module#ListePageModule', canActivate: [LoginActivate] },
  { path: 'edit', loadChildren: './pages/edit/edit.module#EditPageModule', canActivate: [LoginActivate] },
  { path: 'edit/:id', loadChildren: './pages/edit/edit.module#EditPageModule', canActivate: [LoginActivate] },
  { path: 'flash', loadChildren: './pages/flash/flash.module#FlashPageModule', canActivate: [LoginActivate] },
  { path: 'deconnexion', loadChildren: './pages/deconnexion/deconnexion.module#DeconnexionPageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
