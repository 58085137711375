import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from "../../services/auth/auth.service";

@Component({
  selector: 'app-menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.scss'],
})
export class MenuHeaderComponent implements OnInit {
  @Input() currentPage;
  user;
  isConnected;
  constructor(private authService : AuthService) { 
    authService.loggedIn().then((loggedIn)=>{
      this.isConnected = loggedIn;
    });

    authService.getCurrentUser().then((result)=>{
      result.subscribe((user) => {
        this.user = user;
      });
    });

  }

  ngOnInit() {}

}
