import { NgModule } from '@angular/core';
import { MenuHeaderComponent } from "./menu-header/menu-header.component";
import { HttpModule } from "@angular/http";
import { IonicModule } from "@ionic/angular";
import { CommonModule } from "@angular/common";
import { CustomMaterialModule } from 'src/app/core/material.module';
import { RouterModule } from '@angular/router';


@NgModule({
    declarations: [
        MenuHeaderComponent
    ],
    imports: [
        CommonModule,
        HttpModule,
        IonicModule.forRoot(),      
        CustomMaterialModule,
        RouterModule
    ],
    entryComponents: [
        MenuHeaderComponent,
    ],
    exports: [
        MenuHeaderComponent,
    ],
    providers: [
    ]
})
export class ComponentsModule { }

